import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// for new Order
const getVisaCards = createAsyncThunk("getVisaCards", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getAllVisasCard`,config);
        return response.data;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {getVisaCards}