import React from "react";

const HomeMainSection = () => {
  return (
    <div className="homeMainSection">
      <div className="homeMainSection__container">
        <div className="homeMainSection__left">
          <div className="homeMainSection__heading">
            Trusted By Travelers, <br /> Loved By Nomads
          </div>
          <div className="homeMainSection__para">
            VisaFu makes tourist e-visas simple and stress-free, helping you
            travel without worries. Whether it’s a vacation or an adventure
            abroad, we ensure quick, smooth processing with expert support.
            Start your journey confidently with VisaFu!
          </div>
        </div>
        <div className="homeMainSection__right">
          <div>
            <img
              src="./images/statues.png"
              className="homeMainSection__img"
              alt="visafu 7 wonders"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMainSection;
