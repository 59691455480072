import React from "react";
import { useNavigate } from "react-router-dom"; // Import navigation hook
import "./aboutUs.scss";

const AboutUs = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleApplyNow = () => {
    navigate("/"); // Redirect to HomeMainSection (homepage)
  };

  return (
    <div className="about-container">
      {/* Hero Section */}
      <section className="about-hero">
        <h1 className="about-title">About VisaFu</h1>
        <p className="about-subtitle">
        Your journey should be about experiences,
         not visa hassles. At VisaFu, we take the stress out of travel 
         so you can focus on exploring the world.
        </p>
      </section>

      {/* Mission Section */}
      <section className="about-mission">
        <div className="about-text">
          <h2>Our Mission</h2>
          <p>
          We started VisaFu with a clear goal: To make visa applications effortless,
          fast, and stress-free. Using cutting-edge technology,
          we eliminate paperwork chaos and ensure you get your visa with just a few clicks.
          </p>
        </div>
        <div className="about-image">
          <img src= "./images/mission.png" alt="Our Mission" />
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="about-why">
        <h2>Why Choose VisaFu?</h2>
        <div className="about-features">
          <div className="feature-card">
            <h3>🔍 Expert Guidance</h3>
            <p>Stay ahead with the latest visa rules for over 20 countries.</p>
          </div>
          <div className="feature-card">
            <h3>⚡ Lightning-Fast Approvals</h3>
            <p>Skip the waiting—our AI-powered system speeds up processing.</p>
          </div>
          <div className="feature-card">
            <h3>💼 Business & Tourism</h3>
            <p>Whether for vacations or corporate trips, we’ve got you covered.</p>
          </div>
          <div className="feature-card">
            <h3>🛡️ Secure & Trusted</h3>
            <p>Your personal data stays protected with the best encryption standards.</p>
          </div>
        </div>
      </section>

      {/* Our Story Section */}
      <section className="about-story">
        <div className="about-text">
          <h2>Our Story</h2>
          <p>
          We've been in your shoes- filling endless forms, 
          facing last-minute visa rejections, and dealing with confusing paperwork. 
          That’s why we built VisaFu: A simple, transparent, and technology-driven 
          solution that turns a frustrating process into a seamless one.
          </p>
        </div>
        <div className="about-image">
          <img src="./images/story.png" alt="Our Story" />
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="about-testimonials">
        <h2>What Our Customers Say</h2>
        <div className="testimonial">
          <p>“VisaFu made my visa application seamless! I got my approval in just 2 days.”</p>
          <h4>- Priya Sharma, India</h4>
        </div>
        <div className="testimonial">
          <p>“As a frequent traveler, I rely on VisaFu for quick and error-free visa processing.”</p>
          <h4>- Harsh Chouhan, India</h4>
        </div>
      </section>

      {/* Footer CTA Section */}
      <section className="about-cta">
        <h2>Ready to Apply for Your Visa?</h2>
        <p>Get started today with VisaFu and experience a seamless visa application process.</p>
        <button className="cta-button" onClick={handleApplyNow}>
          Apply Now
        </button>
      </section>
    </div>
  );
};

export default AboutUs;
