import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Search } from "lucide-react";

const VisaSearch = ({ visas = [] }) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredVisas, setFilteredVisas] = useState([]);
  const searchRef = useRef(null); // Reference for the container

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value === "") {
      // If search bar is empty, clear the filtered visas
      setFilteredVisas([]);
    } else {
      // Filter visas based on country name, city name, or short country code
      const filtered = visas.filter(
        (visa) =>
          visa.countryName.toLowerCase().includes(value) ||
          visa.city.some((city) => city.toLowerCase().includes(value)) ||
          visa.countryName.toLowerCase().slice(0, 3).includes(value)
      );

      setFilteredVisas(filtered);
    }
  };

  const handleClickOutside = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setFilteredVisas([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="visa-search" ref={searchRef}>
      <div className="visa-search__container">
        <Search size={40} className="visa-search__icon" />
        <input
          type="text"
          className="visa-search__input"
          value={searchValue}
          onChange={handleSearch}
          onClick={handleSearch}
          placeholder="Search visa by country or city..."
        />
      </div>

      {/* Display filtered results */}
      {filteredVisas.length > 0 && (
        <ul className="visa-search__list">
          {filteredVisas.map((visa) => (
            <Link to={`/visa/${visa._id}`} className="visa-search__link">
              <li className="visa-search__list-item" key={visa._id}>
                <img
                  className="visa-search__image"
                  src={visa.cardImage}
                  alt="country"
                />
                <div className="visa-search__country-name">
                  {visa.countryName}
                </div>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

export default VisaSearch;
