import { createSlice } from "@reduxjs/toolkit"
import { getVisaDetail } from "./VisaAction";

//for creating new Order
const visaDetailSlice = createSlice({
    name: 'VisaDetail',
    initialState: { loading: false, error: false, visaDetail: {} },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVisaDetail.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVisaDetail.fulfilled, (state, action) => {
                state.visaDetail = action.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getVisaDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});


const visaDetailAction = visaDetailSlice.actions;

export {visaDetailAction, visaDetailSlice};