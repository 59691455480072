import React from 'react'
import { useSelector } from 'react-redux'
import Loading from './Loading';
import { Navigate, useLocation } from 'react-router';

const ProtectedRoute = ({children}) => {
  const location = useLocation();
  const {isAuthenticated, loading} = useSelector((state)=>state.user);

  return (
   <> {loading?<Loading></Loading>:isAuthenticated?children:<Navigate to='/signIn' state={{ from: location }}></Navigate>}</>
  )
}

export default ProtectedRoute