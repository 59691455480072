import React, { useState, useEffect } from "react";
// import { saveToLocalStorage,getFromLocalStorage } from '../../../components/LocalStorage/localStorage';
import { useNavigate } from "react-router-dom";

const VisaPriceCalculator = ({ visaDetail, selectedCurrency }) => {
  const navigate = useNavigate();
  const [numTravellers, setNumTravellers] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [minSelectableDate, setMinSelectableDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");

  const currencySymbol = selectedCurrency === "USD" ? "$" : "₹";

  const price = parseFloat(
    visaDetail?.price[selectedCurrency].replace(/[^0-9.-]+/g, "")
  );
  const visaFuCharges = parseFloat(
    visaDetail?.visaFuCharges[selectedCurrency].replace(/[^0-9.-]+/g, "")
  );
  const tax = parseFloat(
    visaDetail?.tax[selectedCurrency].replace(/[^0-9.-]+/g, "")
  );

  const calculateDeliveryDate = (deliverDays) => {
    const currentDate = new Date();
    let remainingDays = parseInt(deliverDays, 10);
    let deliveryDate = new Date(currentDate);

    // Loop until all deliverable days are counted
    while (remainingDays > 0) {
      deliveryDate.setDate(deliveryDate.getDate() + 1); // Move to the next day

      // Check if the day is not Saturday (6) or Sunday (0)
      if (deliveryDate.getDay() !== 6 && deliveryDate.getDay() !== 0) {
        remainingDays--; // Only count weekdays
      }
    }

    // Add one additional day after calculation
    deliveryDate.setDate(deliveryDate.getDate() + 1);

    return deliveryDate;
  };

  useEffect(() => {
    if (visaDetail?.deliverDays) {
      const deliverDays = parseInt(visaDetail.deliverDays, 10);

      if (!isNaN(deliverDays)) {
        const calculatedDate = calculateDeliveryDate(deliverDays);
        const formattedDate = calculatedDate.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
        setMinSelectableDate(formattedDate); // Update the state with the calculated date
      }
    }
  }, [visaDetail?.deliverDays]);

  // useEffect(() => {
  //   const savedData = getFromLocalStorage('travelData');
  //   if (savedData) {
  //       setDepartureDate(savedData?.departureDate);
  //       setNumTravellers(parseInt(savedData?.numTravellers));
  //   }
  // }, []);

  useEffect(() => {
    function getBestDiscount(numOfTravel, percentageOffArray) {
      const applicableDiscounts = percentageOffArray.filter(
        (item) => item.numOfTravel <= numOfTravel
      );
      if (applicableDiscounts.length > 0) {
        const bestDiscount = applicableDiscounts.sort(
          (a, b) => b.numOfTravel - a.numOfTravel
        )[0];
        return bestDiscount.percentageOff;
      }
      return 0;
    }

    setDiscount(getBestDiscount(numTravellers, visaDetail?.percentageOff));
    let total =
      currencySymbol === "₹"
        ? parseInt(
            numTravellers * price +
              numTravellers * (visaFuCharges + tax) -
              ((visaFuCharges + tax) * discount * numTravellers) / 100
          )
        : numTravellers * price +
          numTravellers * (visaFuCharges + tax) -
          ((visaFuCharges + tax) * discount * numTravellers) / 100;
    setTotalPrice(total);
  }, [
    numTravellers,
    price,
    tax,
    visaFuCharges,
    discount,
    visaDetail?.percentageOff,
    currencySymbol,
  ]);

  const increaseTravelers = () => {
    setNumTravellers((prev) => prev + 1);
  };

  const decreaseTravelers = () => {
    setNumTravellers((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleClick = (e) => {
    e.target.showPicker(); // This will open the date picker
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const fairValue = {
    //     totalAmount: price*numTravellers,
    //     gst: tax*numTravellers,
    //     visaFuCharge: visaFuCharges*numTravellers,
    //     discount: discount,
    //     totalAddOnsCharge: 0,
    //     grandTotal: totalPrice
    // // }
    // const travelData = { visaId: visaDetail?._id};
    // saveToLocalStorage('travelData', travelData);
    navigate(
      `/applyVisa/${visaDetail?._id}?departureDate=${departureDate}&numTravellers=${numTravellers}`
    );
  };

  return (
    <div className="visaPriceCalculator">
      <div className="visaPriceCalculator__box">
        <div className="visaPriceCalculator__label">
          <img
            src="/SVG/traveller.svg"
            alt="traveller icon"
            className="visaPriceCalculator__icon"
          ></img>
          <div>Travellers</div>
        </div>
        <div className="visaPriceCalculator__controls">
          <div className="visaPriceCalculator__num" onClick={decreaseTravelers}>
            -
          </div>
          <div className="visaPriceCalculator__num-text">{numTravellers}</div>
          <div className="visaPriceCalculator__num" onClick={increaseTravelers}>
            +
          </div>
        </div>
      </div>

      <div className="visaPriceCalculator__box--1">
        <div className="visaPriceCalculator__label--1">
          <img
            src="/SVG/wallet.svg"
            alt="wallet icon"
            className="visaPriceCalculator__icon"
          ></img>
          <div>Visa Fees</div>
        </div>
        <div className="visaPriceCalculator__num-text">
          {currencySymbol}
          {currencySymbol === "₹"
            ? parseInt(price * numTravellers)
            : price * numTravellers}
        </div>
      </div>

      <div className="visaPriceCalculator__box--1">
        <div className="visaPriceCalculator__label--1">
          <svg className="visaPriceCalculator__icon" aria-hidden="true">
            <use href="/sprite.svg#icon-tag"></use>
          </svg>
          <div>
            <div>
              VisaFu Charges{" "}
              {discount > 0 ? (
                <div className="visaPriceCalculator__discount">
                  {discount}% off
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="visaPriceCalculator__tax">(including tax)</div>
          </div>
        </div>
        <div className="visaPriceCalculator__num-text">
          {currencySymbol}{" "}
          {currencySymbol === "₹"
            ? parseInt(
                (visaFuCharges + tax) * numTravellers -
                  ((visaFuCharges + tax) * discount * numTravellers) / 100
              )
            : (visaFuCharges + tax) * numTravellers -
              ((visaFuCharges + tax) * discount * numTravellers) / 100}
        </div>
      </div>
      <div className="visaPriceCalculator__line"></div>
      <div className="visaPriceCalculator__box--1">
        <div className="visaPriceCalculator__label">Total</div>
        <div className="visaPriceCalculator__num-text">
          {currencySymbol}
          {totalPrice}
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="visaPriceCalculator__box--1">
          <input
            type="date"
            className="visaPriceCalculator__input-date"
            onClick={handleClick}
            min={minSelectableDate}
            value={departureDate}
            onChange={(e) => setDepartureDate(e.target.value)}
            required
          />
          <label
            className={
              departureDate
                ? "visaPriceCalculator__display-none"
                : "visaPriceCalculator__input-placeholder"
            }
          >
            Departure Date
          </label>
        </div>
        <button type="submit" className="visaPriceCalculator__button">
          Start Application
        </button>
      </form>
    </div>
  );
};

export default VisaPriceCalculator;
