import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// for new Order
const getVisaDetail = createAsyncThunk("getVisaDetails", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getVisa/${data.id}`,config);
        return response.data;
    }catch(error) {
        return rejectWithValue(error.response.data);
    }
});

export {getVisaDetail}