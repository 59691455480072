import React from "react";
import "./appliedVisa.scss";

const AppliedVisa = ({ visaApplied }) => {
  // console.log(`visaApplied`, visaApplied);
  return (
    <div className="visaApplied-card">
      <div className="visaApplied-card-image">
        {/* Placeholder for image */}
      </div>
      <div className="visaApplied-card-content">
        <h1 className="visaApplied-card-title">{visaApplied.heading}</h1>

        <div className="visaApplied-card-dates">
          <div className="visaApplied-date-section">
            <p className="visaApplied-date-label">Applied</p>
            <p className="visaApplied-date-value">{visaApplied.applied}</p>
          </div>

          <div className="visaApplied-airplane-icon-container">
            <div className="visaApplied-airplane-icon">
              <img
                width={"30px"}
                height={"30px"}
                // style={{ transform: "rotate(180deg)" }}
                src="/airplane.svg"
              />
            </div>
          </div>

          <div className="visaApplied-date-section">
            <p className="visaApplied-date-label">Estimated</p>
            <p className="visaApplied-date-value">{visaApplied.estimated}</p>
          </div>
        </div>

        <p className="visaApplied-travelers-info">
          You are{" "}
          <span className="visaApplied-highlight">
            {visaApplied.travellers} travelers
          </span>{" "}
          with{" "}
          <span className="visaApplied-highlight">
            {visaApplied.addOns} E-Sims
          </span>{" "}
          for addon.
        </p>

        <p className="visaApplied-amount-info">
          Total Amount Paid:{" "}
          <span className="visaApplied-amount">₹{visaApplied.totalAmount}</span>
        </p>

        <p className="visaApplied-status-info">
          Status:{" "}
          <span className="visaApplied-status">{visaApplied.Status}</span>
        </p>
      </div>
    </div>
  );
};

export default AppliedVisa;
