import React, { useState } from "react";
import VisaCard from "../VisaCard";

const Pagination = ({ totalCards, cardsPerPage, currency }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages based on total cards and cards per page
  const totalPages = Math.ceil(totalCards.length / cardsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Get current cards to display on the page
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = totalCards.slice(indexOfFirstCard, indexOfLastCard);

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage || i === currentPage + 1 || i === currentPage - 1) {
        pages.push(
          <button
            key={i}
            onClick={() => handleClick(i)}
            className={`pagination__button ${
              i === currentPage ? "pagination__button--active" : ""
            }`}
          >
            {i}
          </button>
        );
      }
    }
    return pages;
  };

  const renderCards = () => {
    return currentCards.map((card, index) => (
      <VisaCard key={index} card={card} currency={currency}></VisaCard>
    ));
  };

  return (
    <div className="pagination-container">
      <div className="card-grid" key={currentPage}>
        {renderCards()}
      </div>

      <div className="pagination">
        <button
          className="pagination__button pagination__button--prev"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        {renderPageNumbers()}

        <button
          className="pagination__button pagination__button--next"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
