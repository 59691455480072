import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ selectedCurrency, onCurrencyChange }) => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__container">
          <div className="footer__section footer__section--logo">
            <div className="footer__logo-container">
              <img
                src="/images/simple-logo.svg"
                alt="VisaFu Logo"
                className="footer__logo"
              />
            </div>
            <div className="footer__company-box">
              <div className="footer__company">Company</div>
              <div className="footer__company-name">
                VOYASIM TRAVEL SERVICES LLP
              </div>
            </div>
          </div>

          <div className="footer__payment-container">
            <div className="footer__section--middle">
              <div className="footer__section footer__section--links">
                <div className="footer__heading">Quick Links</div>
                <ul className="footer__links-list">
                  <li className="footer__link-item">
                    <Link to="/about-us" className="footer__link">
                      About Us
                    </Link>
                  </li>
                  <li className="footer__link-item">
                    <Link to="/contact-us" className="footer__link">
                      Contact Us
                    </Link>
                  </li>
                  <li className="footer__link-item">
                    <Link to="/terms-and-conditions" className="footer__link">
                      Terms and Conditions
                    </Link>
                  </li>
                  <li className="footer__link-item">
                    <Link
                      to="/cancellation-and-refund-policy"
                      className="footer__link"
                    >
                      Refund Policy
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="footer__section footer__section--contact">
                <div className="footer__heading">Contact Details</div>
                <div className="footer__address">
                  <div className="footer__address-label">Address</div>
                  <div className="footer__address-text">
                    704, Palm Court, Mehrauli-Gurgaon Road, Gurugram, Haryana,
                    122007
                  </div>
                </div>
                <div className="footer__phone">
                  <div className="footer__phone-label">Phone No.</div>
                  <div className="footer__phone-number">(+91) 9266607095</div>
                </div>
              </div>
            </div>

            <div className="footer__section footer__section--payment">
              <div className="footer__heading">Payment Methods</div>
              <div className="footer__currency">
                <select
                  className="footer__currency-select"
                  value={selectedCurrency}
                  onChange={onCurrencyChange}
                >
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
              <div className="footer__payment-methods">
                <div>
                  <img
                    src="/images/bhim.svg"
                    alt="Visa"
                    className="footer__payment-method"
                  />
                  <img
                    src="/images/paytm.svg"
                    alt="Mastercard"
                    className="footer__payment-method"
                  />
                  <img
                    src="/images/phonepe.svg"
                    alt="Paypal"
                    className="footer__payment-method"
                  />
                  <img
                    src="/images/rupay.svg"
                    alt="Stripe"
                    className="footer__payment-method"
                  />
                </div>
                <div>
                  <img
                    src="/images/amex.svg"
                    alt="Visa"
                    className="footer__payment-method"
                  />
                  <img
                    src="/images/maestro.svg"
                    alt="Mastercard"
                    className="footer__payment-method"
                  />
                  <img
                    src="/images/mastercard.svg"
                    alt="Paypal"
                    className="footer__payment-method"
                  />
                  <img
                    src="/images/visa.svg"
                    alt="Stripe"
                    className="footer__payment-method"
                  />
                </div>
              </div>
              <div className="footer__email-box">
                <a className="footer__email" href="mailto:info@visafu.com">
                  <svg aria-hidden="true" className="footer__social-icon">
                    <use href="sprite.svg#icon-email"></use>
                  </svg>
                  info@visafu.com
                </a>
                <a
                  className="footer__email"
                  href="https://wa.me/919266607095"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg aria-hidden="true" className="footer__social-icon">
                    <use href="sprite.svg#icon-whatsapp"></use>
                  </svg>
                  WhatsApp Chat
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__copyright">
          Copyright 2021 Tour Guide. All Rights Reserved
        </div>
        <div className="footer__social">
          <a
            href="https://www.linkedin.com/company/visafu/"
            className="footer__social-link"
            target="__blank"
          >
            <svg aria-hidden="true" className="footer__social-icons">
              <use href="sprite.svg#icon-linkedin"></use>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/visafuofficial/"
            className="footer__social-link"
            target="__blank"
          >
            <svg aria-hidden="true" className="footer__social-icons">
              <use href="sprite.svg#icon-instagram"></use>
            </svg>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61564063335494"
            className="footer__social-link"
            target="__blank"
          >
            <svg aria-hidden="true" className="footer__social-icons">
              <use href="sprite.svg#icon-facebook"></use>
            </svg>
          </a>
          <a
            href="https://x.com/visafuofficial"
            className="footer__social-link"
            target="__blank"
          >
            <svg aria-hidden="true" className="footer__social-icons">
              <use href="sprite.svg#icon-x"></use>
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
