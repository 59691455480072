import React from "react";
import { useSelector } from "react-redux";
import AppliedVisa from "../../components/AppliedVisa";

const Dashboard = ({ selectedCurrency }) => {
  const { user } = useSelector((state) => state.user);

  // console.log(`user : `, user.visaAppliedIds);
  const visaApplieds = [
    {
      image: "",
      heading: "Dubai Visa for American Citizens",
      applied: "Sep 11, 2024",
      estimated: "Nov 10, 2024",
      travellers: "2",
      addOns: "2",
      totalAmount: "22564",
      Status: "Ongoing",
    },
    {
      image: "",
      heading: "Dubai Visa for American Citizens",
      applied: "Sep 11, 2024",
      estimated: "Nov 10, 2024",
      travellers: "2",
      addOns: "2",
      totalAmount: "22564",
      Status: "Ongoing",
    },
    {
      image: "",
      heading: "Dubai Visa for American Citizens",
      applied: "Sep 11, 2024",
      estimated: "Nov 10, 2024",
      travellers: "2",
      addOns: "2",
      totalAmount: "22564",
      Status: "Ongoing",
    },
  ];

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <div className="dashboard__user-info">
          <div className="dashboard__user-icon">
            <svg className="dashboard__icon" aria-hidden="true" role="img">
              <use href="/sprite.svg#icon-Profile"></use>
            </svg>
            <div className="dashboard__user-mobile">+ {user.mobile}</div>
          </div>
          <button className="dashboard__logout-button">LogOut</button>
        </div>
      </div>
      <div className="dashboard__content">
        <div className="dashboard__content-heading">Visa Status</div>
        <div className="dashboard__visa-list">
          {user.visaAppliedIds.length > 0 &&
            user.visaAppliedIds.map((visa, index) => {
              return <AppliedVisa key={index} visaApplied={visa}></AppliedVisa>;
            })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
