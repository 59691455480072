import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../../store/User/UserAction";
import { verifyOTP } from "../../../store/User/UserAction";
import { userAction } from "../../../store/User/UserReducer";
import Loading from "../../../components/Loading";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router";

const OTP = ({ mobile, setOpenOTP }) => {
  const [otp, setOtp] = useState("");
  const [errorField, seterrorField] = useState("");
  const [timer, setTimer] = useState(60); // Timer for resend button

  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.from?.pathname || "/";
  const searchParams = location.state?.from?.search || "";

  const url = `${redirectPath}${searchParams}`;

  const dispatch = useDispatch();
  const {
    verifyOTPLoading,
    verifyOTPError,
    verifyOTPMessage,
    verifyOTPSuccess,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (verifyOTPError) {
      dispatch(userAction.clearVerifyOTPError());
      toast.error(verifyOTPMessage, {
        position: "top-right",
        bodyStyle: { fontSize: "16px" },
      });
    }

    if (verifyOTPSuccess) {
      dispatch(userAction.clearVerifyOTPSuccess());
      navigate(url);
      toast.success(verifyOTPMessage, {
        position: "top-right",
        bodyStyle: { fontSize: "16px" },
      });
    }
  }, [
    verifyOTPError,
    verifyOTPMessage,
    verifyOTPSuccess,
    dispatch,
    navigate,
    url,
  ]);

  // Function to handle OTP submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!otp) {
      seterrorField("OTP is required.");
    } else {
      seterrorField("");
      dispatch(verifyOTP({ mobile, otp }));
    }
  };

  // Function to handle resend OTP logic
  const handleResend = () => {
    if (timer === 0) {
      dispatch(logIn({ mobile: mobile }));
    }
  };

  // Timer for countdown before allowing resend
  React.useEffect(() => {
    if (timer === 0) return;
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  // Function to handle back button click
  const handleBack = () => {
    setOpenOTP(false); // Navigates back to the login page
  };

  return (
    <div className="otp">
      {verifyOTPLoading ? (
        <Loading></Loading>
      ) : (
        <div className="otp__wrapper">
          <button onClick={handleBack} className="otp__back-button">
            ←
          </button>
          <div className="otp__header">
            <div className="otp__logo">
              <svg className="otp__icon" aria-hidden="true" role="img">
                <use href="/sprite.svg#icon-Margin"></use>
              </svg>
            </div>
            <div className="otp__title">
              <div className="otp__main-title">Visas on time</div>
              <div className="otp__sub-title">And sign-ups in no time.</div>
            </div>
          </div>

          <form className="otp__form" onSubmit={handleSubmit}>
            <label className="otp__label" htmlFor="otp-input">
              Enter OTP
            </label>
            <input
              type="text"
              id="otp-input"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="otp__input"
              maxLength="6" // Typically, OTPs are 6 digits
              placeholder="Enter OTP"
            />
            {errorField && <div className="otp__errorField">{errorField}</div>}

            <div className="otp__buttons">
              <button
                type="button"
                onClick={handleResend}
                className="otp__resend-button"
                disabled={timer > 0}
              >
                {timer > 0 ? `Resend in ${timer}s` : "Resend OTP"}
              </button>
              <button type="submit" className="otp__continue-button">
                Continue
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default OTP;
