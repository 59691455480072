import React from "react";
import StarRating from "../StarRating";

const ReviewCard = ({ card }) => {
  return (
    <div className="reviewCard">
      <div className="reviewCard__img-box">
        <img
          src={card?.imageUrl}
          alt="review person"
          className="reviewCard__img"
        />
      </div>

      <div className="reviewCard__detail">
        <blockquote className="reviewCard__review">{card?.review}</blockquote>
        <StarRating rating={card?.rating}></StarRating>
        <div className="reviewCard__name">{card?.name}</div>
      </div>
    </div>
  );
};

export default ReviewCard;
