import { createSlice } from "@reduxjs/toolkit"
import {getVisaCards} from './VisaCardAction'

//for creating new Order
const visaCardSlice = createSlice({
    name: 'VisaCard',
    initialState: { loading: true, error: false, visaCards: [] },
    reducers: {
        clearError: (state) => {
            state.error = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVisaCards.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVisaCards.fulfilled, (state, action) => {
                state.visaCards = action.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getVisaCards.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});


const visaCardAction = visaCardSlice.actions;

export {visaCardAction, visaCardSlice};