import React, { useEffect } from "react";
import Pagination from "../../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { getVisaCards } from "../../../store/VisaCard/VisaCardAction";
import VisaSearch from "../../../layout/VisaSearch";
import Loading from "../../../components/Loading";
const VisaCardSection = ({ currency }) => {
  const dispatch = useDispatch();

  const { visaCards, loading } = useSelector((state) => state.visaCards);

  useEffect(() => {
    dispatch(getVisaCards({}));
  }, [dispatch]);

  return (
    <div className="visaCardSection">
      <VisaSearch visas={visaCards}></VisaSearch>
      <div className="visaCardSection__heading">Explore Popular Countries</div>
      <div className="visaCardSection__subHeading">
        Dreaming of your next adventure or planning to expand your horizons?
        We’ve got you covered!
      </div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <Pagination
          currency={currency}
          totalCards={visaCards}
          cardsPerPage={12}
        ></Pagination>
      )}
    </div>
  );
};

export default VisaCardSection;
