import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// for new Order
const logIn = createAsyncThunk("logIn", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{'Content-Type':'application/json'}};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sendOtp`,data,config);
        return response.data;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const verifyOTP = createAsyncThunk("verifyOTP", async (data,{rejectWithValue}) => {
    try {
        const config={headers:{"Content-Type":"application/json"},withCredentials:true};
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/verifyOtp`,data,config);
        return response.data;
    }catch(error) {
        return rejectWithValue(error.response.data.message);
    }
});

const loadUser = createAsyncThunk("get/getuserDetail", async (data,{rejectWithValue}) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/getProfile`,{withCredentials:true});
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

export {logIn, verifyOTP, loadUser}