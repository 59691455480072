import React, { useState, useEffect, useRef } from "react";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../components/LocalStorage/localStorage";
import TravellerVerificationForm from "../TravellerVerificationForm";
// import { useDispatch, useSelector } from 'react-redux';
// import { verifyDocument } from '../../store/Verify/VerifyAction';
// import { verifyAction } from '../../store/Verify/VerifyReducer';
// import Lottie from 'lottie-react';
// import passportVerify from '../../animation/passportVerify.json';

const DocumentUpload = ({
  numOfTravellers,
  visaDetail,
  currentTraveller,
  setCurrentTraveller,
  setPage,
}) => {
  const photoInputRef = useRef(null);
  const passportFrontInputRef = useRef(null);
  const passportBackInputRef = useRef(null);
  const usVisaInputRef = useRef(null);

  const [photo, setPhoto] = useState("");
  const [passportFront, setPassportFront] = useState("");
  const [passportBack, setPassportBack] = useState("");
  const [usVisa, setUsVisa] = useState("");
  const [data, setData] = useState(false);
  const [changeFormData, setChangeFormData] = useState(false);
  const [passportFrontError, setPassportFrontError] = useState(false);
  const [extractData, setExtractData] = useState("");
  // const dispatch = useDispatch();

  // const { verify, verifySuccess, loading } = useSelector((state) => state.verify);

  // useEffect(() => {
  //     if (verifySuccess) {
  //         const travellerData = { photo, passportFront, passportBack, usVisa, data: verify };
  //         setData(verify);
  //         saveToLocalStorage(`visaFutraveller${currentTraveller}`, travellerData);
  //         dispatch(verifyAction.clearVerifySuccess());
  //     }
  // }, [verify, verifySuccess, dispatch, currentTraveller, passportBack, passportFront, photo, usVisa]);

  useEffect(() => {
    const savedTravellerData = getFromLocalStorage(
      `visaFutraveller${currentTraveller}`
    );
    window.scrollTo(0, 0);
    if (savedTravellerData) {
      setPhoto(savedTravellerData.photo);
      setPassportFront(savedTravellerData.passportFront);
      setPassportBack(savedTravellerData.passportBack);
      setUsVisa(savedTravellerData.usVisa);
      setExtractData(savedTravellerData.passportData);
      setData(true);
    } else {
      setPhoto("");
      setPassportFront("");
      setPassportBack("");
      setUsVisa("");
      setExtractData({});
    }
  }, [currentTraveller]);

  const handleNext = () => {
    if (changeFormData) {
      alert("You have unsaved data. Please save before proceeding.");
    } else if (currentTraveller < numOfTravellers) {
      setCurrentTraveller(currentTraveller + 1);
    }
  };

  const handlePrev = () => {
    if (changeFormData) {
      alert("You have unsaved data. Please save before proceeding.");
    } else if (currentTraveller > 1) {
      setCurrentTraveller(currentTraveller - 1);
    }
  };

  const handleContinue = () => {
    if (changeFormData) {
      alert("You have unsaved data. Please save before proceeding.");
    } else {
      const confirmProceed = window.confirm(
        "Before moving forward, please double-check the traveler's details. Are you sure everything is accurate and ready to proceed?"
      );
      if (confirmProceed) {
        setPage("payment");
        window.scrollTo(0, 0);
      }
    }
  };

  const handleFileChange = (e, setFile, fileName) => {
    const reader = new FileReader();
    let worker = initWorker();
    reader.onload = (e) => {
      setFile(reader.result);
      if (fileName === "passportFront") {
        worker.postMessage({
          cmd: "process",
          image: e.target.result,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  function correctMRZDates(data) {
    function parseDate(yyMMdd, isExpiration = false) {
      const currentYear = new Date().getFullYear() % 100; // Last two digits of current year
      const currentCentury = Math.floor(new Date().getFullYear() / 100) * 100; // Current century

      let year = parseInt(yyMMdd.slice(0, 2), 10);
      let month = parseInt(yyMMdd.slice(2, 4), 10); // No -1 to prevent shifting issues
      let day = parseInt(yyMMdd.slice(4, 6), 10);

      let fullYear;

      if (isExpiration) {
        fullYear =
          year + (year >= currentYear ? currentCentury : currentCentury + 100);
      } else {
        fullYear =
          year + (year > currentYear ? currentCentury - 100 : currentCentury);
      }

      // Format YYYY-MM-DD properly
      return `${fullYear}-${String(month).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
    }

    return {
      ...data,
      birthDate: parseDate(data.birthDate),
      expirationDate: parseDate(data.expirationDate, true),
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const travellerData = {
      photo,
      passportFront,
      passportBack,
      usVisa,
      passportData: extractData,
    };
    setData(true);
    saveToLocalStorage(`visaFutraveller${currentTraveller}`, travellerData);
  };

  // Check if all required files are uploaded
  const areAllFilesUploaded = () => {
    return (
      (!visaDetail?.documentRequirement?.photo || photo) &&
      (!visaDetail?.documentRequirement?.passport ||
        (passportFront && passportBack)) &&
      (!visaDetail?.documentRequirement?.usVisa || usVisa)
    );
  };

  const allRequiredVerified = () => {
    return (
      (!visaDetail?.documentRequirement?.photo || photo) &&
      (!visaDetail?.documentRequirement?.passport ||
        (passportBack && passportFront && !passportFrontError)) &&
      (!visaDetail?.documentRequirement?.usVisa || usVisa) &&
      data
    );
  };

  function initWorker() {
    if (!window.mrz_worker) {
      console.error(
        "window.mrz_worker is not defined. Ensure the script is loaded."
      );
      return null;
    }

    // Convert mrz_worker function to a blob
    const blob = new Blob(
      [window.mrz_worker.toString().replace(/^function .+\{?|\}$/g, "")],
      { type: "text/javascript" }
    );

    const objectURL = URL.createObjectURL(blob);
    const worker = new Worker(objectURL);

    worker.addEventListener("message", async function (e) {
      const data = e.data;
      if (data.type === "result") {
        if (data.result?.parsed?.valid) {
          const parsed = data.result.parsed.fields;
          setExtractData(correctMRZDates(parsed));
          setPassportFrontError(false);
        } else if (data.result?.error) {
          setPassportFrontError(true);
        } else {
          setPassportFrontError(true);
        }
      } else if (data.type === "progress") {
        console.log("progress");
      } else if (data.type === "error") {
        setPassportFrontError(true);
      }
    });

    return worker;
  }

  return (
    <div className="documentUpload">
      {/* {loading && <div className='documentUpload__passportVerify'>
                <Lottie
                    animationData={passportVerify}
                    loop={true}
                    style={{ height: 400, width: 400 }}
                />
            </div>} */}
      <form className="documentUpload__form">
        <div className="documentUpload__heading">
          Traveller {currentTraveller}
        </div>
        <div className="documentUpload__form-box">
          {visaDetail?.documentRequirement?.photo && (
            <div className="documentUpload__input-wrapper">
              <label className="documentUpload__upload-section">
                <div className="documentUpload__upload-section--text">
                  <svg
                    className="documentUpload__upload-icon1"
                    aria-hidden="true"
                  >
                    <use href="\sprite.svg#icon-tick"></use>
                  </svg>
                  <span className="documentUpload__upload-text">Photo</span>
                </div>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setPhoto, "photo")}
                  style={{ display: "none" }}
                  ref={photoInputRef}
                  required
                />
                <svg
                  className="documentUpload__upload-icon2"
                  aria-hidden="true"
                >
                  <use href="\sprite.svg#icon-edit"></use>
                </svg>
              </label>
              {!photo && (
                <img
                  src="/images/upload.png"
                  alt="Uploaded"
                  className="documentUpload__preview"
                  onClick={() => photoInputRef.current.click()}
                />
              )}
              {photo && (
                <img
                  src={photo}
                  alt="Uploaded"
                  className="documentUpload__preview"
                />
              )}
              {/* {(data.hasOwnProperty('photoVerifyStatus') && !data?.photoVerifyStatus && data.hasOwnProperty('photoVerifyError')) && <div className='documentUpload__error'>{data['photoVerifyError']}</div>} */}
            </div>
          )}

          {visaDetail?.documentRequirement?.passport && (
            <div className="documentUpload__input-wrapper">
              <label className="documentUpload__upload-section">
                <div className="documentUpload__upload-section--text">
                  <svg
                    className="documentUpload__upload-icon1"
                    aria-hidden="true"
                  >
                    <use href="\sprite.svg#icon-tick"></use>
                  </svg>
                  <span className="documentUpload__upload-text">
                    Passport Front
                  </span>
                </div>
                <input
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setPassportFront, "passportFront")
                  }
                  style={{ display: "none" }}
                  ref={passportFrontInputRef}
                  required
                />
                <svg
                  className="documentUpload__upload-icon2"
                  aria-hidden="true"
                >
                  <use href="\sprite.svg#icon-edit"></use>
                </svg>
              </label>
              {!passportFront && (
                <img
                  src="/images/upload.png"
                  alt="Uploaded Passport Front"
                  className="documentUpload__preview"
                  onClick={() => passportFrontInputRef.current.click()}
                />
              )}
              {passportFront && (
                <img
                  src={passportFront}
                  alt="Uploaded Passport Front"
                  className="documentUpload__preview"
                />
              )}
              {/* {(data.hasOwnProperty('passportFrontVerifyStatus') && !data?.passportFrontVerifyStatus && data.hasOwnProperty('passportFrontVerifyError')) && <div className='documentUpload__error'>{data['passportFrontVerifyError']}</div>} */}
              {passportFrontError && (
                <div className="documentUpload__error">
                  Invalid! Please Upload Again
                </div>
              )}
            </div>
          )}

          {visaDetail?.documentRequirement?.passport && (
            <div className="documentUpload__input-wrapper">
              <label className="documentUpload__upload-section">
                <div className="documentUpload__upload-section--text">
                  <svg
                    className="documentUpload__upload-icon1"
                    aria-hidden="true"
                  >
                    <use href="\sprite.svg#icon-tick"></use>
                  </svg>
                  <span className="documentUpload__upload-text">
                    Passport Back
                  </span>
                </div>
                <input
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setPassportBack, "passportBack")
                  }
                  style={{ display: "none" }}
                  ref={passportBackInputRef}
                  required
                />
                <svg
                  className="documentUpload__upload-icon2"
                  aria-hidden="true"
                >
                  <use href="\sprite.svg#icon-edit"></use>
                </svg>
              </label>
              {!passportBack && (
                <img
                  src="/images/upload.png"
                  alt="Uploaded Passport Back"
                  className="documentUpload__preview"
                  onClick={() => passportBackInputRef.current.click()}
                />
              )}
              {passportBack && (
                <img
                  src={passportBack}
                  alt="Uploaded Passport Back"
                  className="documentUpload__preview"
                />
              )}
              {/* {(data.hasOwnProperty('passportBackVerifyStatus') && !data?.passportBackVerifyStatus && data.hasOwnProperty('passportBackVerifyError')) && <div className='documentUpload__error'>{data['passportBackVerifyError']}</div>} */}
            </div>
          )}

          {visaDetail?.documentRequirement?.usVisa && (
            <div className="documentUpload__input-wrapper">
              <label className="documentUpload__upload-section">
                <div className="documentUpload__upload-section--text">
                  <svg
                    className="documentUpload__upload-icon1"
                    aria-hidden="true"
                  >
                    <use href="\sprite.svg#icon-tick"></use>
                  </svg>
                  <span className="documentUpload__upload-text">
                    Extra Visa
                  </span>
                </div>
                <input
                  type="file"
                  onChange={(e) =>
                    handleFileChange(e, setPassportBack, "extraVisa")
                  }
                  style={{ display: "none" }}
                  ref={usVisaInputRef}
                  required
                />
                <svg
                  className="documentUpload__upload-icon2"
                  aria-hidden="true"
                >
                  <use href="\sprite.svg#icon-edit"></use>
                </svg>
              </label>
              {!usVisa && (
                <img
                  src="/images/upload.png"
                  alt="Uploaded US Visa"
                  className="documentUpload__preview"
                  onClick={() => usVisaInputRef.current.click()}
                />
              )}
              {usVisa && (
                <img
                  src={usVisa}
                  alt="Uploaded US Visa"
                  className="documentUpload__preview"
                />
              )}
              {/* {(data.hasOwnProperty('usVisaVerifyStatus') && !data?.usVisaVerifyStatus && data.hasOwnProperty('usVisaVerifyError')) && <div className='documentUpload__error'>{data['usVisaVerifyError']}</div>} */}
            </div>
          )}
        </div>
        <div className="documentUpload__submit">
          <button
            type="submit"
            className="documentUpload__submit-button"
            disabled={!areAllFilesUploaded()} // Disable button if not all files are uploaded
            onClick={handleSubmit}
          >
            Verify
          </button>
        </div>
      </form>
      {allRequiredVerified() && (
        <TravellerVerificationForm
          travellerNumber={currentTraveller}
          visaDetail={visaDetail}
          setChangeFormData={setChangeFormData}
        />
      )}
      <div className="documentUpload__button">
        <button
          onClick={handlePrev}
          disabled={currentTraveller === 1}
          className="documentUpload__button-action"
        >
          Back
        </button>
        {currentTraveller !== numOfTravellers && (
          <button
            onClick={handleNext}
            disabled={!allRequiredVerified()}
            className="documentUpload__button-action"
          >
            Next
          </button>
        )}
        {currentTraveller === numOfTravellers && (
          <button
            disabled={!allRequiredVerified()}
            onClick={handleContinue}
            className="documentUpload__button-action"
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;
