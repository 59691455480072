import React from "react";
import StarRating from "../StarRating";

const VisaReviewCard = ({ review, index }) => {
  return (
    <div key={index} className="visaReviewCard">
      <div className="visaReviewCard__box">
        <div className="visaReviewCard__img-box">
          <img
            src={review?.image}
            alt="review person"
            className="visaReviewCard__img"
          ></img>
        </div>
        <div className="visaReviewCard__detail">
          <div className="visaReviewCard__name">{review?.name}</div>
          <div className="visaReviewCard__location">
            <svg className="visaReviewCard__icon" aria-hidden="true">
              <use href="/sprite.svg#icon-location"></use>
            </svg>
            {review?.location}
          </div>
        </div>
      </div>
      <div className="visaReviewCard__rating">
        <StarRating rating={review?.rating}></StarRating>
      </div>
      <div className="visaReviewCard__mainLine">{review?.mainLine}</div>
      <div className="visaReviewCard__review">{review?.review}</div>
    </div>
  );
};

export default VisaReviewCard;
