import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import { logIn } from "../../../store/User/UserAction";
import { userAction } from "../../../store/User/UserReducer";
import Loading from "../../../components/Loading";
import OTP from "../OTP";
import { toast } from "react-toastify";

const LogIn = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorField, seterrorField] = useState("");
  const [openOTP, setOpenOTP] = useState(false);

  const dispatch = useDispatch();
  const { loading, success, error, message } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (success) {
      setOpenOTP(true);
      dispatch(userAction.clearSuccess());
      toast.success(message, {
        position: "top-right",
        bodyStyle: { fontSize: "16px" },
      });
    }

    if (error) {
      setOpenOTP(false);
      dispatch(userAction.clearError());
      toast.error(message, {
        position: "top-right",
        bodyStyle: { fontSize: "16px" },
      });
    }
  }, [success, error, dispatch, message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!mobileNumber) {
      seterrorField("Mobile number is required.");
    } else {
      dispatch(logIn({ mobile: mobileNumber }));
    }
  };

  return (
    <div className="login">
      {loading ? (
        <Loading></Loading>
      ) : !openOTP ? (
        <div className="login__wrapper">
          <div className="login__header">
            <div className="login__logo">
              <svg className="login__icon" aria-hidden="true" role="img">
                <use href="/sprite.svg#icon-Margin"></use>
              </svg>
            </div>
            <div className="login__title">
              <div className="login__main-title">Visas on time</div>
              <div className="login__sub-title">And sign-ups in no time.</div>
            </div>
          </div>
          <form className="login__form" onSubmit={handleSubmit}>
            <label className="login__label" htmlFor="mobile-number">
              Enter Your Phone
            </label>
            <PhoneInput
              country={"in"}
              value={mobileNumber}
              onChange={(phone) => setMobileNumber(phone)}
              inputClass="login__input"
              buttonClass="login__country-select"
              dropdownClass="login__dropdown"
            />
            {errorField && (
              <div className="login__errorField">{errorField}</div>
            )}
            <button type="submit" className="login__button">
              Continue
            </button>
          </form>
        </div>
      ) : (
        <OTP mobile={mobileNumber} setOpenOTP={setOpenOTP}></OTP>
      )}
    </div>
  );
};

export default LogIn;
