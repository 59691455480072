import React from "react";

const NotFound = () => {
  return (
    <div className="not-found">
      <h1 className="not-found__heading">404</h1>
      <p className="not-found__text">
        Oops! The page you're looking for doesn't exist.
      </p>
      <a href="/" className="not-found__link">
        Go back to Home
      </a>
    </div>
  );
};

export default NotFound;
