import './App.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './screens/Home';
import VisaDetail from './screens/VisaDetail';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import TravellerNavigation from './screens/TravellerNavigation';
import ScrollToTop from './components/ScrollToTop';
import TermsAndConditions from './screens/TermsAndConditions';
import CancellationAndRefundPolicy from './screens/CancellationAndRefundPolicy';
import LogIn from './screens/Sign/LogIn';
import { useDispatch } from 'react-redux';
import { loadUser } from './store/User/UserAction';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './screens/DashBoard';
import ContactUs from './screens/ContactUs';
import AboutUs from './screens/AboutUs'

function App() {
  const [currency, setCurrency] = useState('INR');
  const dispatch = useDispatch();

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  useEffect(()=>{
    dispatch(loadUser({}));
  },[dispatch]);

  return (
    <>
    <ScrollToTop></ScrollToTop>
    <Navbar selectedCurrency={currency} onCurrencyChange={handleCurrencyChange} />
    <Routes>
      <Route path="/">
        <Route
            index
            element={
                    <Home currency={currency}/>
            }
        />
      </Route>
      <Route path="/visa/:id">
        <Route
            index
            element={
                    <VisaDetail selectedCurrency={currency}/>
            }
        />
      </Route>
      <Route path="/applyVisa/:id">
        <Route
            index
            element={
                   <ProtectedRoute><TravellerNavigation selectedCurrency={currency}></TravellerNavigation></ProtectedRoute>
            }
        />
      </Route>
      <Route path="/terms-and-conditions">
        <Route
            index
            element={
              <TermsAndConditions></TermsAndConditions>
            }
        />
      </Route>
      <Route path="/cancellation-and-refund-policy">
        <Route
            index
            element={
             <CancellationAndRefundPolicy></CancellationAndRefundPolicy> 
            }
        />
      </Route>
      <Route path="/contact-us">
        <Route
            index
            element={
             <ContactUs></ContactUs> 
            }
        />
      </Route>
      <Route path="/about-us">
        <Route
            index
            element={
             <AboutUs></AboutUs> 
            }
        />
      </Route>
      <Route path="/signIn">
        <Route
            index
            element={
              <LogIn></LogIn>
            }
        />
      </Route>
      <Route path="/dashboard">
        <Route
            index
            element={
                   <ProtectedRoute><Dashboard selectedCurrency={currency}></Dashboard></ProtectedRoute>
            }
        />
      </Route>
    </Routes>
    <Footer selectedCurrency={currency} onCurrencyChange={handleCurrencyChange}></Footer>
    </>
  );
}

export default App;
