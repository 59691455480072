import React from "react";

const VisaOffer = () => {
  return (
    <div className="visa-offer">
      <div className="visa-offer__image">
        <img
          src="/images/guarantee.png"
          alt="guarantee"
          className="visa-offer__image-img"
        ></img>
      </div>
      <div className="visa-offer__content">
        <div className="visa-offer__title">On Time Guarantee</div>
        <div className="visa-offer__description">
          Get a 100% refund on Visafu charges if we don’t deliver your visa on
          time.
        </div>
      </div>
    </div>
  );
};

export default VisaOffer;
