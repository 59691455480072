import React, { useState } from "react";
import {
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { motion } from "framer-motion";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    gmail: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = new FormData();
    submissionData.append("access_key", "0ba26edf-43ce-4c1b-ad1c-63fe440b3356"); // Your Web3Forms access key
    submissionData.append("name", formData.name);
    submissionData.append("phone", formData.phone);
    submissionData.append("gmail", formData.gmail); // Ensure Gmail is included
    submissionData.append("message", formData.message);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: submissionData,
      });

      const result = await response.json();
      if (result.success) {
        alert("Message submitted successfully!");
        setFormData({ name: "", phone: "", gmail: "", message: "" }); // Reset form after submission
      } else {
        alert("Submission failed, please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Something went wrong.");
    }
  };

  return (
    <motion.div
      className="contact-page"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      {/* Left Side - Contact Info */}
      <div className="contact-page__left">
        <h1 className="contact-page__title">Get in Touch</h1>

        <div className="contact-page__info">
          <FaWhatsapp size={22} />
          <span>
            WhatsApp: <strong>9266607095</strong>
          </span>
        </div>

        <div className="contact-page__info">
          <FaPhone size={22} />
          <span>
            Call: <strong>9266607095, 9266607096</strong>
          </span>
        </div>

        <div className="contact-page__info">
          <FaEnvelope size={22} />
          <span>
            Email: <strong>support@visafu.com</strong>
          </span>
        </div>

        <div className="contact-page__info">
          <FaMapMarkerAlt size={22} />
          <span>
            704, Palm Court, Mehrauli-Gurgaon Road, Gurugram, Haryana, 122007
          </span>
        </div>

        {/* Google Maps Embed */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.235524095016!2d77.05225237564731!3d28.472452875752538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1954208e58c9%3A0xb06b6d4632c1beb4!2sVisafu!5e0!3m2!1sen!2sin!4v1739388037775!5m2!1sen!2sin"
          width="100%"
          height="250"
          className="contact-page__map"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>

      {/* Right Side - Contact Form */}
      <motion.form
        className="contact-page__form"
        onSubmit={handleSubmit}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="contact-page__form-group">
          <label htmlFor="name" className="contact-page__label">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="contact-page__input"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="contact-page__form-group">
          <label htmlFor="phone" className="contact-page__label">
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="contact-page__input"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="contact-page__form-group">
          <label htmlFor="gmail" className="contact-page__label">
            Gmail
          </label>
          <input
            type="email"
            id="gmail"
            name="gmail"
            className="contact-page__input"
            value={formData.gmail}
            onChange={handleChange}
            required
          />
        </div>

        <div className="contact-page__form-group">
          <label htmlFor="message" className="contact-page__label">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="contact-page__textarea"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        <div className="contact-page__form-btn">
          <button type="submit" className="contact-page__button">
            Submit
          </button>
        </div>
      </motion.form>
    </motion.div>
  );
};

export default ContactUs;
