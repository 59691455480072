import React from "react";
import { Link } from "react-router-dom";

const formatDate = (date) => {
  const options = {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleDateString("en-US", options);
};

const VisaCard = ({ card, currency }) => {
  const getDate = (deliverDays) => {
    const currentDate = new Date();
    let remainingDays = parseInt(deliverDays, 10);
    let deliveryDate = new Date(currentDate);

    // Loop until all deliverable days are counted
    while (remainingDays > 0) {
      deliveryDate.setDate(deliveryDate.getDate() + 1); // Move to the next day

      // Check if the day is not Saturday (6) or Sunday (0)
      if (deliveryDate.getDay() !== 6 && deliveryDate.getDay() !== 0) {
        remainingDays--; // Only count weekdays
      }
    }

    const formattedDate = formatDate(deliveryDate); // Format as needed
    return formattedDate;
  };

  return (
    <Link to={`/visa/${card?._id}`} style={{ textDecoration: "none" }}>
      <div className="visa-card">
        <div className="visa-card__image-wrapper">
          <img
            className="visa-card__image"
            src={card?.cardImage}
            alt={card?.countryName}
          ></img>
        </div>
        <div className="visa-card__details">
          <div className="visa-card__heading">{card?.cardHeading}</div>
          <div className="visa-card__price">
            {currency === "INR" ? card?.price?.INR : card?.price?.USD}
            <br />
            <span>per person</span>
          </div>
        </div>
        <div className="visa-card__delivery">
          <svg className="visa-card__icon" aria-hidden="true">
            <use href="sprite.svg#icon-clock"></use>
          </svg>
          Get On &nbsp; <span>{getDate(card?.deliverDays)}</span>
        </div>
      </div>
    </Link>
  );
};

export default VisaCard;
