import React, { useState, useEffect } from "react";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../components/LocalStorage/localStorage";
import { toast } from "react-toastify";

const TravellerVerificationForm = ({
  travellerNumber,
  visaDetail,
  setChangeFormData,
}) => {
  const [travellerData, setTravellerData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const savedTravellerData = getFromLocalStorage(
      `visaFutraveller${travellerNumber}`
    );
    if (savedTravellerData) {
      setTravellerData(savedTravellerData);
    }
  }, [travellerNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTravellerData((prevState) => ({
      ...prevState,
      [name.includes("us") ? "usVisaData" : "passportData"]: {
        ...prevState[name.includes("us") ? "usVisaData" : "passportData"],
        [name]: value,
      },
    }));
    setChangeFormData(true);
  };

  const isFormComplete = () => {
    const requiredFields = visaDetail?.documentRequirement?.passport
      ? [
          "firstName",
          "lastName",
          "documentNumber",
          "expirationDate",
          "birthDate",
          "sex",
        ]
      : [];

    // if (visaDetail?.documentRequirement?.usVisa) {
    //     requiredFields.push('usPassportNumber', 'usIssueDate', 'expirationDate');
    // }

    return requiredFields.every((field) => {
      const section = field.includes("us")
        ? travellerData.usVisaData
        : travellerData.passportData;
      return section && section[field];
    });
  };

  const handleSubmit = () => {
    if (isFormComplete()) {
      const savedTravellerData = getFromLocalStorage(
        `visaFutraveller${travellerNumber}`
      );
      saveToLocalStorage(`visaFutraveller${travellerNumber}`, {
        ...savedTravellerData,
        passportData: travellerData.passportData,
      });
      toast.success("Details has been saved", {
        position: "bottom-right",
        bodyStyle: { fontSize: "16px" },
      });
      setError(null);
      setChangeFormData(false);
    } else {
      setError("Please fill all required fields before saving.");
    }
  };

  return (
    <div className="travellerVerificationForm">
      <div className="travellerVerificationForm__heading">
        Traveller {travellerNumber} Information
      </div>
      {visaDetail?.documentRequirement?.passport && (
        <form className="travellerVerificationForm__form">
          <div className="travellerVerificationForm__field">
            <label
              className="travellerVerificationForm__label"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={travellerData?.passportData?.firstName || ""}
              onChange={handleInputChange}
              className="travellerVerificationForm__input"
              required
            />
          </div>
          <div className="travellerVerificationForm__field">
            <label
              className="travellerVerificationForm__label"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={travellerData?.passportData?.lastName || ""}
              onChange={handleInputChange}
              className="travellerVerificationForm__input"
              required
            />
          </div>
          <div className="travellerVerificationForm__field">
            <label
              className="travellerVerificationForm__label"
              htmlFor="passportNumber"
            >
              Passport Number
            </label>
            <input
              type="text"
              name="documentNumber"
              id="documentNumber"
              value={travellerData?.passportData?.documentNumber || ""}
              onChange={handleInputChange}
              className="travellerVerificationForm__input"
              required
            />
          </div>
          <div className="travellerVerificationForm__field">
            <label
              className="travellerVerificationForm__label"
              htmlFor="passportValidTill"
            >
              Passport Valid Till
            </label>
            <input
              type="text"
              name="expirationDate"
              id="expirationDate"
              value={travellerData?.passportData?.expirationDate || ""}
              onChange={handleInputChange}
              className="travellerVerificationForm__input"
              required
            />
          </div>
          <div className="travellerVerificationForm__field">
            <label className="travellerVerificationForm__label" htmlFor="dob">
              DOB
            </label>
            <input
              type="text"
              name="birthDate"
              id="birthDate"
              value={travellerData?.passportData?.birthDate || ""}
              onChange={handleInputChange}
              className="travellerVerificationForm__input"
              required
            />
          </div>
          <div className="travellerVerificationForm__field">
            <label
              className="travellerVerificationForm__label"
              htmlFor="gender"
            >
              Gender
            </label>
            <input
              type="text"
              name="sex"
              id="sex"
              value={travellerData?.passportData?.sex || ""}
              onChange={handleInputChange}
              className="travellerVerificationForm__input"
              required
            />
          </div>
        </form>
      )}
      {/* {visaDetail?.documentRequirement?.usVisa && (
                <form className='travellerVerificationForm__form'>
                    <div className='travellerVerificationForm__field'>
                        <label className='travellerVerificationForm__label' htmlFor="usPassportNumber">US Passport Number</label>
                        <input
                            type="text"
                            name="usPassportNumber"
                            id="usPassportNumber"
                            value={travellerData?.usVisaData?.passportNumber || ''}
                            onChange={handleInputChange}
                            className='travellerVerificationForm__input'
                            required
                        />
                    </div>
                    <div className='travellerVerificationForm__field'>
                        <label className='travellerVerificationForm__label' htmlFor="usIssueDate">US Issue Date</label>
                        <input
                            type="text"
                            name="usIssueDate"
                            id="usIssueDate"
                            value={travellerData?.usVisaData?.issueDate || ''}
                            onChange={handleInputChange}
                            className='travellerVerificationForm__input'
                            required
                        />
                    </div>
                    <div className='travellerVerificationForm__field'>
                        <label className='travellerVerificationForm__label' htmlFor="expirationDate">US Expiration Date</label>
                        <input
                            type="text"
                            name="expirationDate"
                            id="expirationDate"
                            value={travellerData?.usVisaData?.expirationDate || ''}
                            onChange={handleInputChange}
                            className='travellerVerificationForm__input'
                            required
                        />
                    </div>
                </form>
            )} */}
      <div className="travellerVerificationForm__submit">
        <button
          type="button"
          onClick={handleSubmit}
          className="travellerVerificationForm__submit-button"
        >
          Save
        </button>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default TravellerVerificationForm;
