function getSEOData(pageName, visaName = "") {
    const seoData = {
        home: {
            title: "VisaFu | Simplifying Visa Applications for Stress-Free Travel",
            description: "VisaFu makes visa applications simple and stress-free, helping you travel without worries. Quick and smooth processing with expert support.",
            keywords: "VisaFu, visa applications, travel, vacation, study abroad, work visa, expert support"
        },
        aboutUs: {
            title: "About Us | VisaFu - Your Trusted Visa Partner",
            description: "Learn about VisaFu's mission to simplify visa applications. Explore our reliable services to make your travel planning stress-free.",
            keywords: "About VisaFu, visa services, travel solutions, reliable visa processing"
        },
        contactUs: {
            title: "Contact Us | VisaFu - Reach Out for Visa Assistance",
            description: "Get in touch with VisaFu for expert visa assistance. We're here to help you plan your travel with ease and confidence.",
            keywords: "Contact VisaFu, visa assistance, customer support, travel planning"
        },
        default: {
            title: "VisaFu | Simplifying Visa Applications",
            description: "VisaFu makes visa applications simple and stress-free, helping you travel without worries. Start your journey confidently with us.",
            keywords: "VisaFu, visa applications, stress-free travel, reliable services"
        }
    };

    // Dynamic SEO for specific visas
    if (pageName === "visa" && visaName) {
        return {
            title: `${visaName} Visa | VisaFu - Simplified Application Process`,
            description: `Apply for your ${visaName} visa effortlessly with VisaFu. Enjoy quick and smooth processing with expert guidance for a stress-free travel experience.`,
            keywords: `${visaName} visa, ${visaName} travel visa, apply ${visaName} visa, VisaFu services, visa application`
        };
    }

    return seoData[pageName] || seoData.default;
}

export default getSEOData;
